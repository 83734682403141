import React from "react";
import Gif from "./Gif";

export default function App() {
	return (
		<div>
			<Gif />
		</div>
	);
}
